<template>
         <div class="container px-3">
          <a @click="onMenu('home')" class="navbar-brand pe-3">
            <img src="/img/icon.jpg" width="47" alt="blockchainrdc">
            BlockchainRDC
          </a>
          <div id="navbarNav" class="offcanvas offcanvas-end">
            <div class="offcanvas-header border-bottom">
              <h5 class="offcanvas-title">Menu</h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown">
                  <a href="#" class="nav-link dropdown-toggle active" data-bs-toggle="dropdown" aria-current="page">Entreprise</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-lg-flex">
                      <div class="mega-dropdown-column pt-lg-3 pb-lg-4">
                        <ul class="list-unstyled mb-0" style="text-align:justify">
                          <li><a class="btn dropdown-item" @click="onMenu('apropos')">A propos de nous</a></li>
                          <li><a class="btn dropdown-item" @click="onMenu('mission')">Notre Mission</a></li>
                          <li><a class="btn dropdown-item" @click="onMenu('vision')">Notre Vision</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Découvrir</a>
                  <div class="dropdown-menu">
                    <div class="d-lg-flex pt-lg-3">
                      <div class="mega-dropdown-column">
                        <h6 class="px-3 mb-2">Blockchain</h6>
                        <ul class="list-unstyled mb-3">
                          <li><a class="btn dropdown-item py-1" @click="onMenu('historique')">Historique</a></li>
                          <li><a class="btn dropdown-item py-1" @click="onMenu('comprehension')">Compréhension</a></li>
                          <li><a class="btn dropdown-item py-1" @click="onMenu('fonctionnement')">Fonctionnement</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Cas d'usage</a>
                  <ul class="dropdown-menu">
                    <li><a class="btn dropdown-item" @click="onMenu('monetaire')">Monetaire</a></li>
                    <li><a class="btn dropdown-item" @click="onMenu('enseignement')">Enseignement</a></li>
                    <li><a class="btn dropdown-item" @click="onMenu('cadastre')">Cadastre</a></li>
                    <li><a class="btn dropdown-item" @click="onMenu('election')">Election</a></li>
                  </ul>
                </li>
              </ul>
            </div>     
          </div>
          <div class="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">
            <!--input type="checkbox" class="form-check-input" id="theme-mode">
            <label class="form-check-label d-none d-sm-block" for="theme-mode">Light</label>
            <label class="form-check-label d-none d-sm-block" for="theme-mode">Dark</label-->
            <label @click="onMenu('block')" class="btn form-check-label d-none d-sm-block"><i class="bx fs-5 lh-1 me-3">&#9916;</i> Block</label>
          </div>
          <button type="button" class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <a @click="onMenu('blockchain')" class="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex" target="_blank" rel="noopener">
            <i class="bx fs-5 lh-1 me-3">&#9921;</i>
            Blockchain
          </a>
        </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        onMenu(link){
            this.$router.push('/'+link);
        }
    },
}
</script>