<template>
  <div style="background-color:#041836">
        <!-- Google Tag Manager (noscript)-->
    <!--noscript>
      <iframe src="//www.googletagmanager.com/ns.html?id=GTM-WKV3GT5" height="0" width="0" style="display: none; visibility: hidden;"></iframe>
    </noscript-->

    <!-- Page wrapper for sticky footer -->
    <!-- Wraps everything except footer to push footer to the bottom of the page if there is little content -->
    <main class="page-wrapper">


      <!-- Navbar -->
      <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page -->
      <header class="header navbar navbar-expand-lg navbar-light position-absolute navbar-sticky">
        <NavBar/>
      </header>

    <section class="position-relative py-5 py-lg-5 pt-5">
        <div class="container position-relative zindex-5 py-2 py-md-5 py-lg-5 mt-5">
          <div class="row justify-content-center text-center pt-xl-2  mb-1 mb-lg-3" style="margin-top:40px">
            <div class="col-xl-6 col-lg-7 col-md-8 col-sm-11 mt-lg-5">
              <h2 class="mb-4">Création d'un bloc</h2>
              <p class="text-muted mb-0">Plusieurs informations sont regroupées sous la forme de blocs. Chaque bloc doit ensuite être hashé puis validé par les nœuds du réseau en utilisant une méthode algorithmique</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
              <form class="row needs-validation" novalidate>
                <div class="col-sm-6 mb-1">
                  <label for="fn" class="form-label">Nom</label>
                  <input v-model="nom" type="text" id="fn" class="form-control" required>
                  <div class="invalid-feedback">Please, enter your first name!</div>
                </div>
                <div class="col-sm-6 mb-1">
                  <label for="ln" class="form-label">Postnom</label>
                  <input v-model="postnom" type="text" id="ln" class="form-control" required>
                  <div class="invalid-feedback">Please, enter your last name!</div>
                </div>
                <div class="col-sm-6 mb-1">
                  <label for="email" class="form-label">Adresse email</label>
                  <input v-model="email" type="email" id="email" class="form-control" required>
                  <div class="invalid-feedback">Please, provide a valid email address!</div>
                </div>
                <div class="col-sm-6 mb-1">
                  <label for="phone" class="form-label">Phone</label>
                  <input v-model="phone" type="text" id="phone" class="form-control" data-format="custom" data-delimiter="-" data-blocks="3 2 2 2 3" data-numeral="12" placeholder="000-00-00-00-000">
                </div>
                <div class="col-12 mb-1">
                  <label for="message" class="form-label">Message</label>
                  <textarea v-model="message" id="message" class="form-control" rows="4" required></textarea>
                  <div class="invalid-feedback">Please, enter your message!</div>
                </div>
              </form>
              <div class="col-12 text-center pt-2 pt-md-3 pt-lg-4">
                  <button @click="getHash()" class="btn btn-primary shadow-primary btn-lg">Obtenir le hash</button>
                </div>
                <!-- BG shape -->
                <div class="position-absolute end-0 bottom-0 text-primary">
                    <svg width="469" height="343" viewBox="0 0 469 343" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.08" fill-rule="evenodd" clip-rule="evenodd" d="M273.631 680.872C442.436 768.853 639.315 708.216 717.593 558.212C795.871 408.208 732.941 212.157 564.137 124.176C395.333 36.195 198.453 96.8326 120.175 246.836C41.8972 396.84 104.827 592.891 273.631 680.872ZM236.335 752.344C440.804 858.914 688.289 788.686 789.109 595.486C889.928 402.286 805.903 159.274 601.433 52.7043C396.964 -53.8654 149.479 16.3623 48.6595 209.562C-52.1598 402.762 31.8652 645.774 236.335 752.344Z" fill="currentColor"/><path opacity="0.08" fill-rule="evenodd" clip-rule="evenodd" d="M298.401 633.404C434.98 704.59 598.31 656.971 664.332 530.451C730.355 403.932 675.946 242.827 539.367 171.642C402.787 100.457 239.458 148.076 173.435 274.595C107.413 401.114 161.822 562.219 298.401 633.404ZM288.455 652.464C434.545 728.606 611.369 678.429 683.403 540.391C755.437 402.353 695.402 228.725 549.312 152.583C403.222 76.4404 226.398 126.617 154.365 264.655C82.331 402.693 142.365 576.321 288.455 652.464Z" fill="currentColor"/></svg>
                </div>
            </div>
          </div>
    </section>

      <!-- Hero -->
      <section class="jarallax position-relative d-flex align-items-center min-vh-100 bg-light" style="background-image: url(assets/img/hero-bg.png);" data-jarallax data-img-position="0% 100%" data-speed="0.5">
        <div class="container position-relative zindex-5">
          <div class="row justify-content-md-start justify-content-center">
            <div class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between text-md-start text-center">
              <div class="col-12">
                <v-flex xs12 sm12 md12 lg12 xl12 class="b-chain">
                    <v-layout wrap justify-center>
                        <v-flex xs11 sm11 md11 lg11 xl11 class="b-block">
                            <div class="b-hash">
                                <p>
                                <span>Hash : </span><br>
                                <span>{{hash}}</span>
                                </p>
                            </div>
                            <!--infinite-slide-bar :duration="duration">
                            <div class="items">
                                <div v-for="i in 11" :key="i" class="b-block">
                                <div class="f-left pd-t-8px mg-r-10px">
                                    index: {{i}}
                                </div>
                                <div class="b-hash">
                                    <p>
                                    <span>Hash : </span>
                                    <span>00004c9133fe8b528520929c46b3b486e79ce3d66fd5a481fb3b9163b5678d44</span>
                                    </p>
                                </div>
                                </div>
                            </div>
                            </infinite-slide-bar-->
                        </v-flex>
                    </v-layout>
                </v-flex>
              </div>

            </div>
          </div>
        </div>
      </section>

    </main>


    <!-- Footer -->
    <Footer/>

  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
//import InfiniteSlideBar from 'vue-infinite-slide-bar'
export default {
  name: 'Home',
  components:{NavBar,Footer},
  data () {
    return {
      imgPath: this.$imgPath,
      duration:'35s',
      hash:'',
      nom:'',
      postnom:'',
      email:'',
      phone:'',
      message:''
    }
  },
  created(){
      //this.getBlock();
  },
  methods: {
      getBlock(){
            /*this.isLoading=true;
            this.success=false;*/
            const url=this.$baseURI+"/master/hash";
                let formData= new FormData();
                formData.append('index',2);
                formData.append('data',"Bonjourx");
                this.$http.post(url,formData).then((response)=>{
                    this.hash=response.data;
                
            }).catch(()=>{ })
      },
      getHash(){
          var data=this.nom+this.postnom+this.email+this.phone+this.message;
          if(data!=''){
              const url=this.$baseURI+"/master/hash";
                let formData= new FormData();
                formData.append('index',1);
                formData.append('data',data);
                this.$http.post(url,formData).then((response)=>{
                    this.hash=response.data;
                
            }).catch(()=>{ })
          }
      }
  },
}
</script>
<style scoped>
.bg-header{
  background-color: var(--primary-color);
}
.bg-header-hover{
      background-image: url(https://moralis.io/wp-content/uploads/2021/07/hero_blue_circle2new.svg);
    content: '';
    width: 1278px;
    display: block;
    height: 1168px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top: -280px;
    right: -300px;
}
.s-left{
  width: 100%;
  min-height:600px;
  position: relative;
  background-image: url(/img/bg-blue.svg);
  content: '';
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  top:-95px;
  left: -250px;
}
.s-right{
   width: 100%;
  min-height:600px;
  position: relative;
  background-image: url(/img/bg-blue.svg);
  content: '';
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  top:80px;
  left: 0;
}
.s-right img{
  width: 85%;
}
.b-hover{
  position:absolute;
  color: white;
}
.s-text{
  margin-top:200px;
}
.items {
  display: flex; 
  justify-content: space-around; 
} 
.b-chain{
  /*background-color: var(--primary-color);*/
  margin-top:-200px
}
.b-block{
  width:200px;
  border: 1px solid silver;
  border-radius: 8px;
  margin: 0 10px;
  padding: 15px;
  color: whitesmoke;
  text-align:center;
}
.b-hash{
  word-break: break-all;
  font-size: 40px;
  font-weight: bold;
}

@media (max-width:986px) {
  .s-left{
    width: 100%;
    min-height:200px;
    position: relative;
    background-image: url(/img/bg-blue.svg);
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top:-40px;
    left: -60px;
  }
  .s-right{
    width: 100%;
    min-height:250px;
    position: relative;
    background-image: url(/img/bg-blue.svg);
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top:0px;
    left: 0;
  }
  .s-right img{
    width: 85%;
  }
  .b-hover{
    position:absolute;
    color: white;
  }
  .s-text{
    margin-top:100px;
  }

  .b-chain{
    min-height:300px;
  }

}
</style>
