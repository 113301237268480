import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false
Vue.prototype.$http = axios
//var ip = "http://194.59.166.136/"
var ip = "http://globaliris.net/"
//var ip = "http://localhost/"
Vue.prototype.$baseURI = ip + "api_blockchain_master/v1.0.2/certified"
Vue.prototype.$imgPath = '/img/'

new Vue({
  mounted () {
    AOS.init()
  },
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
