<template>
  <div style="background: #041836;">
     <!-- Google Tag Manager (noscript)-->
    <!--noscript>
      <iframe src="//www.googletagmanager.com/ns.html?id=GTM-WKV3GT5" height="0" width="0" style="display: none; visibility: hidden;"></iframe>
    </noscript-->

    <!-- Page wrapper for sticky footer -->
    <!-- Wraps everything except footer to push footer to the bottom of the page if there is little content -->
    <main class="page-wrapper">

      <!-- Page loading spinner -->
    <!--div class="page-loading active">
      <div class="page-loading-inner">
        <div class="page-spinner"></div><span>Chargement...</span>
      </div>
    </div-->
      <!-- Navbar -->
      <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page -->
      <header class="header navbar navbar-expand-lg navbar-light position-absolute navbar-sticky">
        <NavBar/>
      </header>


      <!-- Hero -->
      <section class="jarallax position-relative d-flex align-items-center min-vh-100 bg-light mb-5 py-lg-5 pt-5" style="background-image: url(assets/img/hero-bg.svg);" data-jarallax data-img-position="0% 100%" data-speed="0.5">
        <div class="container position-relative zindex-5 py-5">
          <div class="row justify-content-md-start justify-content-center">
            <div class="col-md-6 col-sm-8 order-md-1 order-1 d-flex flex-column justify-content-between mt-4 pt-5 text-md-start text-center">
              <div class="mb-md-5 pb-xl-5 mb-4 pt-5 mt-5">

                <!-- Video popup btn -->
                <div class="d-inline-flex align-items-center position-relative mb-5">
                  <a href="#" class="btn btn-video btn-icon btn-lg flex-shrink-0 me-3 stretched-link" data-bs-toggle="video">
                    <i class="bx bx-play"></i>
                  </a>
                  <h4 class="mb-0">Données Cryptées</h4>
                </div>

                <!-- Text -->
                <h1 class="display-2 mb-md-5 mb-3 pb-3 mt-5">
                  La <span class="text-gradient-primary">BlockChain</span> RDCongo
                </h1>
                <div class="d-md-flex align-items-md-start">
                  <!--a href="contacts-v1.html" class="btn btn-lg btn-primary flex-shrink-0 me-md-4 mb-md-0 mb-sm-4 mb-3">Work with us</a-->
                  <p class="d-lg-block d-none mb-0 ps-md-3" style="color:white">La RDC dans la lignée de la révolution numérique avec la technologie Blockchain
Stockage de données et transmission d'information, transparence, sécurité et fonctionnement sans orgne de contrôl.</p>
                </div>
              </div>

              <!-- Scroll down btn -->
              <div class="d-inline-flex align-items-center justify-content-center justify-content-md-start position-relative">
                <a href="#benefits" data-scroll data-scroll-offset="100" class="btn btn-video btn-icon rounded-circle shadow-sm flex-shrink-0 stretched-link me-3">
                  <i class="bx bx-chevron-down"></i>
                </a>
                <span class="fs-sm" style="color:white">Découvrir plus</span>
              </div>
            </div>

            <!-- Animated gfx -->
            <div class="col-sm-6 col-sm-6 col-9 order-md-2 order-1">
              <img :src="`${imgPath}/bc2.png`" alt="">
            </div>
          </div>
        </div>
      </section>

      <v-layout>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <!-- Benefits (features) -->
          <section class="container mb-5 pt-lg-5 services" id="benefits">
            <div class="swiper pt-3" data-swiper-options='{
              "slidesPerView": 1,
              "pagination": {
                "el": ".swiper-pagination",
                "clickable": true
              },
              "breakpoints": {
                "500": {
                  "slidesPerView": 2
                },
                "991": {
                  "slidesPerView": 3
                }
              }
            }'>
              <div class="swiper-wrapper">

                <!-- Item -->
                <div class="swiper-slide border-end-lg px-2">
                  <div class="text-center">
                    <img src="/img/diplome.png" width="100" alt="diplome" class="d-block mb-4 mx-auto"><br>
                    <h4 class="mb-2 pb-1">Certification des diplômes</h4>
                    <p class="mx-auto" style="max-width: 336px;">Tout diplôme est certifié dans les registres et dispose d’un lien Internet unique qui permet de l’authentifier.</p>
                  </div>
                </div>

                <!-- Item -->
                <div class="swiper-slide border-end-lg px-2">
                  <div class="text-center">
                    <img src="/img/document.png" width="80" alt="document" class="d-block mb-4 mx-auto"><br>
                    <h4 class="mb-2 pb-1">Certification des documents</h4>
                    <p class="mx-auto" style="max-width: 336px;">Un des cas d’usages de la blockchain qui se popularise le plus est la certification de documents via les registres distribués</p>
                  </div>
                </div>

                <!-- Item -->
                <div class="swiper-slide px-2">
                  <div class="text-center">
                    <img src="/img/identity.png" width="60" alt="identity" class="d-block mb-4 mx-auto"><br>
                    <h4 class="mb-2 pb-1">Sécurisation d'identités</h4>
                    <p class="mx-auto" style="max-width: 336px;">Contre les productions des fausses pièces d'identité, la blockchain certifit et sécurise l'identité de chacun.</p>
                  </div>
                </div>
              </div>

              
            </div>
          </section>

        </v-flex>
      </v-layout>
      
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <!-- Featured Projects -->
          <section class="container mb-5 pt-lg-2 pt-xl-4 pb-2 pb-md-3 pb-lg-5" style="color:white">
          
            <p class="mb-4 mx-auto pb-3 fs-lg text-center" style="max-width: 636px;padding:30px 0!important">
              <h3 style="text-align:center">Au coeur de la technologie avec la décentralisation des systèmes</h3><br>
            </p>
          </section>
        </v-flex>
      </v-layout>


      <!-- Services -->
      <section class="container mb-5 pb-2 pb-md-4 pb-lg-5">
        <div class="card border-0 bg-secondary p-md-5 px-sm-2 pt-4 pb-3">
          <div class="card-body mx-auto" style="max-width: 860px;">
            <h2 class="h1 mb-4 text-center">Blockchain RDCongo</h2>
            <p class="mb-4 pb-3 fs-lg text-center text-muted">Nous assure une sécurité avancée avec sont système décentralisé. Données infalsifiables, transabilité et rapidité, etc.</p>

            <!-- Accordion: Alternative style -->
            <div class="accordion" id="accordion-services">

              <!-- Item -->
              <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                <h3 class="accordion-header" id="heading-1">
                  <button class="accordion-button fs-xl shadow-none rounded-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">A propos de nous</button>
                </h3>
                <div class="accordion-collapse collapse show" id="collapse-1" aria-labelledby="heading-1" data-bs-parent="#accordion-services">
                  <div class="accordion-body pt-0">
                    <div class="d-flex flex-md-row flex-column align-items-md-center">
                      <img src="/img/toille.png" width="200" alt="Digital Marketing" class="me-md-4 mb-md-0 mb-3">
                      <div class="ps-md-3">
                        <p class="mb-0">
                          <strong>Qui sommes-nous</strong><br>

«BlockchainR.D.Congo»S.A.R.L» est une entreprise informatique de droit Congolais enregistrée sous le Numéro d’Identification Nationale (ID NAT) No. 01-83-N25214X, RCCM No. CD/KNG/RCCM/17-B-01323, Import No. : A1720355U, INSS No. : 010311231J1 et INPP No. : 39293,00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Item -->
              <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                <h3 class="accordion-header" id="heading-2">
                  <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="true" aria-controls="collapse-2">Notre mission</button>
                </h3>
                <div class="accordion-collapse collapse" id="collapse-2" aria-labelledby="heading-2" data-bs-parent="#accordion-services">
                  <div class="accordion-body pt-0">
                    <div class="d-flex flex-md-row flex-column align-items-md-center">
                      <img src="/img/bc.png" width="200" alt="Web Development" class="me-md-4 mb-md-0 mb-3">
                      <div class="ps-md-3">
                        <p class="mb-0">
                          «BlockchainR.D.Congo»S.A.R.L» a comme souci majeur de ramener ladite technologie Blockchain en R.D.Congo pour qu’elle serve de solution efficace et inaltérable dans divers cas d’usages comme par exemple l’utilisation des monnaies digitales, les transferts de fonds internationaux, les registres fonciers et de propriété, la lutte contre les médicaments contrefaits, l’agriculture et l’exploitation minière (en particulier pour la traçabilité des matières premières), la certification des diplômes et d’autres documents administratifs et l’organisation d’élections...

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Item -->
              <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                <h3 class="accordion-header" id="heading-3">
                  <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="true" aria-controls="collapse-3">Notre Vision </button>
                </h3>
                <div class="accordion-collapse collapse" id="collapse-3" aria-labelledby="heading-3" data-bs-parent="#accordion-services">
                  <div class="accordion-body pt-0">
                    <div class="d-flex flex-md-row flex-column align-items-md-center">
                      <img src="/img/bc1.png" width="200" alt="Application Development" class="me-md-4 mb-md-0 mb-3">
                      <div class="ps-md-3">
                        <p class="mb-0">
                          Amener la République Démocratique du Congo a s’imprégner de la technologie Blockchain qui d’après certains experts serait la plus innovante après «Internet», elle assurera la transparence et la fiabilité en redonnant la confiance perdue dans le chef des Congolais en ce qui concerne le stockage des données.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


 <!-- Our Creative Atmosphere -->
      <section class="container mb-5 pb-lg-5 pt-2 pt-md-4" style="text-align:center">
        <h2 class="h1 d-md-inline-block position-relative mb-md-5 mb-4 text-md-start text-center">
          Nous sommes la sécurité
          <!-- Arrow shape -->
          <span class="d-md-block d-none position-absolute top-0 start-100 text-primary ms-4" style="width: 84px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="76" fill="currentColor"><path d="M52.873 59.368c9.856-.312 19.56 1.721 29.317 1.568.935-.074 2.084-1.013 1.722-2.019-3.406-9.705-9.025-18.368-14.874-26.844-.621-.948-2.116-.34-1.754.666-2.829 2.54-2.639 9.432-2.154 13.403-5.592 2.345-10.045 5.522-13.544 10.524-.703 1.11.064 2.705 1.287 2.702zm15.683-12.501c-.656-3.524.486-6.908.824-10.248 3.96 6.725 7.79 13.479 10.542 20.883-7.915-.124-15.674-1.371-23.618-1.625 3.071-3.274 6.922-5.5 11.278-7.295.935-.074 1.149-.938.974-1.715zm-39.5 1.536l25.888-5.82c1.294-.291 1.622-1.86.871-2.779-5.053-6.751-10.236-13.472-15.089-20.54-.592-.819-1.598-.457-1.841.278-.563-.689-1.958-.24-1.725.796.73 4.459 1.908 9.09.451 13.497-1.486 4.278-6.874 7.529-10.379 10.084-.819.592-.615 1.498-.081 2.058-.414 1.181.352 2.776 1.906 2.427zm11.493-13.598c1.774-4.206.005-9.656-1.243-13.999 3.542 6.682 7.932 12.903 12.423 18.964l-19.804 4.453c3.534-2.426 6.952-5.37 8.625-9.418zM7.077 38.931c8.313-1.733 16.584-3.049 24.39-6.435 1.107-.521 1.175-2.032.453-2.822-5.021-5.398-9.712-11.143-15.093-16.324-.346-.33-.764-.372-1.152-.285-.463-.848-2.116-.34-1.754.666 1.028 2.76.992 5.624 1.086 8.459.122 2.964.064 2.705-2.075 4.682-2.369 2.165-5.699 5.497-7.159 8.681-1.537 1.025-.768 3.844 1.303 3.378zm10.806-11.813c.46-.375.602-.951.486-1.469-.711-2.559-.805-5.394-1.257-8.012 3.407 4.266 6.972 8.631 10.608 12.709-5.492 2.186-11.375 3.237-17.228 4.417.201-.317.531-.663.732-.98 1.864-2.595 4.075-4.86 6.66-6.665z"/></svg>
          </span>
        </h2>
        <img src="/img/bcrdc.jpg" alt="Team cover" class="rounded-3">
      </section>




      <!-- Testimonials slider -->
      <section class="container mb-5 pt-2 pb-3 py-md-4 py-lg-5">
        <h2 class="h1 pb-2 pb-lg-0 mb-4 mb-lg-5 text-center">Explication simple</h2>
        <div class="row">
          <div class="col-md-8">
            <div class="card border-0 shadow-sm p-4 p-xxl-5 mb-4 me-xxl-4">

              <!-- Quotation mark -->
              <div class="pb-4 mb-2">
                <span class="btn btn-icon btn-primary btn-lg shadow-primary pe-none">
                  <i class="bx bxs-quote-left"></i>
                </span>
              </div>

              <!-- Slider -->
              <div class="swiper mx-0 mb-md-n2 mb-xxl-n3" data-swiper-options='{
                "spaceBetween": 24,
                "pager": true,
                "loop": true,
                "tabs": true,
                "navigation": {
                  "prevEl": ".page-prev",
                  "nextEl": ".page-next"
                }
              }'>
                <div class="swiper-wrapper">

                  <!-- Item -->
                  <div class="swiper-slide h-auto" data-swiper-tab="#author-1">
                    <figure class="card h-100 position-relative border-0 bg-transparent">
                      <blockquote class="card-body p-0 mb-0">
                        <p class="fs-lg mb-0">
                          Dans la blockchain, toutes les transactions sont regroupées sous la forme de blocs. Chaque bloc doit ensuite être validé par les nœuds du réseau en utilisant une méthode algorithmique. Une fois que le bloc est validé, il est ajouté à la chaîne de blocs et devient donc visible de tous les utilisateurs.
                        </p>
                      </blockquote>
                      
                    </figure>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-none d-md-block">

            <!-- Swiper tabs (Author images) -->
            <div class="swiper-tabs">

              <!-- Author image 1 -->
              <div id="author-1" class="card bg-transparent border-0 swiper-tab active">
                <div class="card-body p-0 rounded-3 bg-size-cover bg-repeat-0 bg-position-top-center" style="background-image: url(assets/img/testimonials/01.jpg);"></div>
                <div class="card-footer d-flex w-100 border-0 pb-0">
                  <div class="border-start-xl ps-xl-4 ms-xl-2">
                    <h5 class="fw-semibold lh-base mb-0">Christian NGANDU</h5>
                    <span class="fs-sm text-muted">PDG <span class="d-xl-none d-inline"> Blockchain RDCongo</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


     

      <!-- Awards -->
      <section class="container mb-5 pb-2 pb-md-4 pb-lg-5">
        <div class="row gy-4 py-xl-2">
          <div class="col-md-4">
            <h2 class="mb-0 text-md-start text-center">Nous accompagnons</h2>
          </div>
          <div class="col-lg-7 offset-lg-1 col-md-8">
            <div class="row row-cols-sm-4 row-cols-2 gy-4">
              <div class="col">
                <div class="position-relative text-center">
                  <img src="/img/wetaltech.png" width="200" alt="Webby" class="d-block mx-auto mb-3" style="background:white">
                  <a href="#" class="text-body justify-content-center fs-sm stretched-link text-decoration-none">Wetaltech</a>
                </div>
              </div>
              <div class="col">
                <div class="position-relative text-center">
                  <img src="/img/primetimesinfo.png" width="200" alt="CSSDA" class="d-block mx-auto mb-3" style="background:white">
                  <a href="#" class="text-body justify-content-center fs-sm stretched-link text-decoration-none">Primetimes info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <!-- Blog + Contact cta -->
      <div class="bg-secondary mb-5 py-5">

        <!-- Blog -->
        <section class="container mb-5 py-lg-5">
          <div class="row justify-space-between py-5">

              <!-- Article -->
              <article class="col-lg-5 col-12 card border-0 shadow-sm overflow-hidden mb-4 pa-5">
                <h3 style="text-align:center">Exploreur</h3>

L'exploreur est notre plateforme qui vous permet de joindre et authentier tout élément enregistré dans nos registres distribués.
              </article>

              <!-- Article -->
              <article class="col-lg-5 col-12 card border-0 shadow-sm overflow-hidden pa-5">
                <h3 style="text-align:center">Notre équipe</h3>

L'équipe de la Blockchain RDCongo est composée des développeurs back-end, front-end, mobile et desktop. Nos spécialités en langages de dev sont diverses : Python, Java, C, Javascript, PHP, ...
              </article>
          </div>
        </section>

      </div>
    </main>


    <!-- Footer -->
    <Footer/>


    <!-- Back to top button -->
    <a href="#top" class="btn-scroll-top" data-scroll>
      <span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
      <i class="btn-scroll-top-icon bx bx-chevron-up"></i>

      <a href="#benefits" data-scroll data-scroll-offset="100" class="btn btn-video btn-icon rounded-circle shadow-sm flex-shrink-0 stretched-link me-3">
        <i class="bx bx-chevron-down"></i>
      </a>
    </a>

  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components:{NavBar,Footer},
  data() {
    return {
      imgPath: this.$imgPath
    }
  },
  created(){
    this.onLoad();
    this.onMode();
  },
  methods: {
    onLoad(){
      window.onload =()=>{
        const preloader = document.querySelector('.page-loading');
        preloader.classList.remove('active');
        setTimeout(function () {
          preloader.remove();
        }, 1000);
      };
    },
    onMode(){
      let mode = window.localStorage.getItem('mode'),
          root = document.getElementsByTagName('html')[0];
      if (mode !== undefined && mode === 'dark') {
        root.classList.add('dark-mode');
      } else {
        root.classList.add('dark-mode');
      }
    }
  },
}
</script>

<style>
  .page-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s .2s ease-in-out;
    transition: all .4s .2s ease-in-out;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
  }
  .dark-mode .page-loading {
    background-color: #041836;
  }
  .page-loading.active {
    opacity: 1;
    visibility: visible;
  }
  .page-loading-inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    opacity: 0;
  }
  .page-loading.active > .page-loading-inner {
    opacity: 1;
  }
  .page-loading-inner > span {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    color: #9397ad;
  }
  .dark-mode .page-loading-inner > span {
    color: #fff;
    opacity: .6;
  }
  .page-spinner {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: .75rem;
    vertical-align: text-bottom;
    border: .15em solid #b4b7c9;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner .75s linear infinite;
    animation: spinner .75s linear infinite;
  }
  .dark-mode .page-spinner {
    border-color: rgba(255,255,255,.4);
    border-right-color: transparent;
  }
  .services{
    
  }
  .swiper-slide{
    display:flex;
    float: left;
    color: white;
  }
  @-webkit-keyframes spinner {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>