<template>
  <div style="background-color:#041836">
        <!-- Google Tag Manager (noscript)-->
    <!--noscript>
      <iframe src="//www.googletagmanager.com/ns.html?id=GTM-WKV3GT5" height="0" width="0" style="display: none; visibility: hidden;"></iframe>
    </noscript-->

    <!-- Page wrapper for sticky footer -->
    <!-- Wraps everything except footer to push footer to the bottom of the page if there is little content -->
    <main class="page-wrapper">


      <!-- Navbar -->
      <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page -->
      <header class="header navbar navbar-expand-lg navbar-light position-absolute navbar-sticky">
        <NavBar/>
      </header>


      <!-- Hero -->
      <section class="jarallax position-relative d-flex align-items-center min-vh-100 bg-light mb-5 py-lg-5 pt-5" style="background-image: url(assets/img/hero-bg.svg);" data-jarallax data-img-position="0% 100%" data-speed="0.5">
        <div class="container position-relative zindex-5 py-5">
          <div class="row justify-content-md-start justify-content-center">
            <div class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between mt-4 pt-2 text-md-start text-center">
              <div class="col-12">
                <v-flex xs12 sm12 md12 lg12 xl12 class="b-chain">
                    <v-layout wrap justify-center>
                        <v-flex xs11 sm11 md11 lg11 xl11>
                            <infinite-slide-bar :duration="duration">
                            <div class="items">
                                <div v-for="i in 11" :key="i" class="b-block">
                                <!--div class="f-left pd-t-8px mg-r-10px">
                                    index: {{i}}
                                </div-->
                                <div class="b-hash">
                                    <p>
                                    <span>Hash : </span>
                                    <label>00004c9133fe8b528520929c46b3b486e79ce3d66fd5a481fb3b9163b5678d44</label>
                                    </p>
                                </div>
                                </div>
                            </div>
                            </infinite-slide-bar>
                        </v-flex>
                    </v-layout>
                </v-flex>
              </div>

            </div>
          </div>
        </div>
      </section>


    </main>


    <!-- Footer -->
    <Footer/>

  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import InfiniteSlideBar from 'vue-infinite-slide-bar'
export default {
  name: 'Home',
  components:{NavBar,InfiniteSlideBar,Footer},
  data () {
    return {
      imgPath: this.$imgPath,
      duration:'35s'
    }
  }
}
</script>
<style scoped>
.bg-header{
  background-color: var(--primary-color);
}
.bg-header-hover{
      background-image: url(https://moralis.io/wp-content/uploads/2021/07/hero_blue_circle2new.svg);
    content: '';
    width: 1278px;
    display: block;
    height: 1168px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top: -280px;
    right: -300px;
}
.s-left{
  width: 100%;
  min-height:600px;
  position: relative;
  background-image: url(/img/bg-blue.svg);
  content: '';
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  top:-95px;
  left: -250px;
}
.s-right{
   width: 100%;
  min-height:600px;
  position: relative;
  background-image: url(/img/bg-blue.svg);
  content: '';
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  top:80px;
  left: 0;
}
.s-right img{
  width: 85%;
}
.b-hover{
  position:absolute;
  color: white;
}
.s-text{
  margin-top:200px;
}
.items {
  display: flex; 
  justify-content: space-around; 
} 
.b-chain{
  /*background-color: var(--primary-color);*/
  margin-top: 100px;
}
.b-block{
  width:200px;
  border: 1px solid silver;
  border-radius: 8px;
  margin: 5px 10px;
  padding: 15px;
  color: whitesmoke;
}
.b-hash{
  word-break: break-all;
  font-size: 30px;
}
.b-hash label{
  color: rgb(10, 206, 10);
  font-size: 30px;
}

@media (max-width:986px) {
  .s-left{
    width: 100%;
    min-height:200px;
    position: relative;
    background-image: url(/img/bg-blue.svg);
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top:-40px;
    left: -60px;
  }
  .s-right{
    width: 100%;
    min-height:250px;
    position: relative;
    background-image: url(/img/bg-blue.svg);
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top:0px;
    left: 0;
  }
  .s-right img{
    width: 85%;
  }
  .b-hover{
    position:absolute;
    color: white;
  }
  .s-text{
    margin-top:100px;
  }

  .b-chain{
    min-height:300px;
  }

}
</style>
