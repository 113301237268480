<template>
  <v-app>
    <div>
      <router-view/>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>
<style>
:root{
  --primary-color:#041836;
}
.nav-bar{
  background: none!important;
}
</style>
